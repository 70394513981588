class Invoice {
    constructor(id, Balance, Date, Ddate, Idinvoice, 
        Lines_details, Name, Payment, Phone, Note, Address, Otrofield ) {
      this.id = id;
      this.Balance = Balance;
      this.Date = Date;
      this.Ddate = Ddate;
      this.Idinvoice = Idinvoice;
      this.Lines_details = Lines_details;
      this.Name = Name;
      this.Payment = Payment;
      this.Phone = Phone;
      this.Note = Note;
      this.Address = Address;
      this.Otrofield = Otrofield;
    }
  }
  
  export default Invoice;
  