import React, {useState} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';

import { Divider } from '@material-ui/core';
import {businessname, bstreet, bcity, bemail, bphone} from '../models/European'
import logo from '../assets/image/logo.png'


const SearchInvoice = () => {

    const [inputfiltro, setInputFiltro] = useState('')
    const [filterPhone, setFilterPhone] = useState(null)

    const searchByPhone = useSelector(state => state.invoices.invoices)



    const  onChangeHandlerInput = (event) => {
        setInputFiltro(event.target.value);   
    }

    const handlerSubmit = () => {
        setFilterPhone(searchByPhone.filter(item => item.Idinvoice === inputfiltro))        
    }

   const print = () => {
      window.print();
    }
    return (
        <>
        <Container>
            <Row>
                <Col className='mt-2'>
                    <Row style={{margin: 5}}>
                    <Link to='/account' style={{textDecoration: 'none', marginTop:10}}>
                      <ArrowBackIcon color='primary' />
                    </Link>                    
                    <Divider variant="middle" />
                    <Autocomplete                      
                      size='small'
                      id="idPhone"
                      options={ searchByPhone}
                      getOptionLabel={(option) => option.Idinvoice}
                      style={{ width: 180 }}
                      renderInput={(params) => <TextField {...params} id="Phone" name="Phone" type='text' label="--Phone Number-- "  variant="standard"  onSelect={onChangeHandlerInput } />}
                    /> 
                    <Divider variant="middle" />
                    <Button  
                        onClick={handlerSubmit}                              
                        type="button"
                         variant="outlined"
                         color="primary"
                         size="small"
                         startIcon={<SearchIcon />}                  
                        >
                         Search
                    </Button>                    
                    </Row>
                </Col>               
            </Row>
        </Container>   
        {
            filterPhone !== null &&
            filterPhone.map(x => 
            <Container style={{marginTop:'40px'}} key={x.id} >   
                <form>
            <Row className='mb-3'>
                <Col sm={3} className='text-center'> 
                <div >                    
                    <img               
                    style={{width: '300px', height:150}}
                    src={logo}
                   alt='..'  />                   
                </div>
                 </Col>
                 <Col sm={6} className="text-center" > 
                <h2 className="text-center">{businessname}</h2>
                <p className="text-center">{bstreet}<br/>
                {bcity}<br/>{bphone}</p>
            </Col>
                <Col sm={3} className="text-center"> 
                     <h3 >Invoice No:</h3>
                     <TextField inputProps={{ disableUnderline: true}} id="Idinvoice" name="Idinvoice" type='number'  variant="standard" readOnly  value={x.Idinvoice} />
                </Col>
            </Row>
            <Divider variant="middle" />
            <Divider className='mb-3' variant="middle" />
            <Row className='mb-1'>    
               <Col>
               <h5 style={{marginTop:12}}>Bill To:</h5>
               <TextField fullWidth  id="Name" name='Name' type='text' label="Full Name" variant="standard" readOnly  value={x.Name} />
               </Col>      
               <Col>
               <h5 style={{marginTop:12}}>Phone:</h5>
               <TextField fullWidth  id="Phone" name="Phone" type='number' label="Phone Number" variant="standard"  readOnly  value={x.Phone} />
               </Col>   
               <Col>
               <h5>Date:</h5>
               <TextField  style={{ width:220, marginTop:17 }}   id="Date" name="Date" type='date'  variant="standard" readOnly  value={x.Date} />
               </Col>                
            </Row>
            
            <h4>Project Information</h4>
            {/* <Divider variant="middle" /> */}
            <Row className='mb-3'>     
              <Col>
              <h5 style={{ fontSize:17 }}>Address:</h5>
              <TextField fullWidth style={{ width:500, marginTop:15, maxWidth:'100%' }} id="Address" name='Address' type='text' label="" variant="standard" value={x.Address} />
              </Col>
              <Col>
              <h5 style={{ fontSize:17 }}>Due Date:</h5> 
              <TextField fullWidth style={{ width:220, marginTop:17 }} size='small'  id="Ddate" name="Ddate" type='datetime-local'  variant="standard" value={x.Ddate} />
              </Col>                   
           </Row>            
            <Row className='mb-5' >
            <table className="table  table-sm ">
                <thead>
                <tr className='text-center'>
                <th style={{width:'20px', borderBottomColor:'black', verticalAlign:'middle'}}>#</th>
                <th style={{width:'330px', borderBottomColor:'black', verticalAlign:'middle'}}>Service Description</th>
                <th style={{width:'80px', borderBottomColor:'black', verticalAlign:'middle'}}>Tons</th>
                <th style={{width:'80px', borderBottomColor:'black', verticalAlign:'middle'}}>Cost x Tons $</th>
                <th style={{width:'80px', borderBottomColor:'black', verticalAlign:'middle'}}> <TextField inputProps={{ disableUnderline: true}} id="Otrofield" name="Otrofield" type='text'  variant="standard" readOnly  value={x.Otrofield} /></th>
                <th style={{width:'80px', borderBottomColor:'black', verticalAlign:'middle'}}>Abesto</th>
                <th style={{width:'80px', borderBottomColor:'black', verticalAlign:'middle'}}>Fuel $</th>
                <th style={{width:'120px', borderBottomColor:'black', verticalAlign:'middle'}}>Trailer rental $</th>
                <th style={{width:'100px', borderBottomColor:'black', verticalAlign:'middle'}}>Total $</th>
              </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{verticalAlign: 'middle' }} className='text-center'>1</td>
                    <td><TextField multiline  fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}} id="Des1" name="Des" type='text' label="" variant="standard"            readOnly  value={x.Lines_details[0].Des} /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Ton1" name="Ton" type='number' label="" variant="standard" readOnly  value={x.Lines_details[0].Ton} /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center'}}} id="Pri1" name="Pri" type='number' label="" variant="standard"  readOnly  value={x.Lines_details[0].Cost} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Otro1" name="Otro" type='number' label="" variant="standard" readOnly  value={x.Lines_details[0].Otro}  /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Abesto1" name="Abesto" type='number' label="" variant="standard" readOnly  value={x.Lines_details[0].Abesto} /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab1" name="Lab" type='number' label="" variant="standard" readOnly  value={x.Lines_details[0].Fuel} /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War1" name="War" type='text' label="" variant="standard"   readOnly  value={x.Lines_details[0].Rental} /></td>
                    <td style={{verticalAlign: 'middle' }} className='text-center' >{((x.Lines_details[0].Cost ?  parseFloat(x.Lines_details[0].Cost) * parseFloat(x.Lines_details[0].Ton) : 0) + (x.Lines_details[0].Otro ? parseFloat(x.Lines_details[0].Otro) : 0 )+ (x.Lines_details[0].Abesto ? parseFloat(x.Lines_details[0].Abesto) : 0 ) + (x.Lines_details[0].Fuel ? parseFloat(x.Lines_details[0].Fuel) : 0 ) + (x.Lines_details[0].Rental ? parseFloat(x.Lines_details[0].Rental) : 0 )).toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td style={{verticalAlign: 'middle' }} className='text-center'>2</td>
                    <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des2" name="Des" type='text' label="" variant="standard"            readOnly  value={x.Lines_details[1].Des}  /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Ton2" name="Ton" type='number' label="" variant="standard" readOnly  value={x.Lines_details[1].Ton}  /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri2" name="Pri" type='number' label="" variant="standard" readOnly  value={x.Lines_details[1].Cost}  /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Otro2" name="Otro" type='number' label="" variant="standard" readOnly  value={x.Lines_details[1].Otro}  /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Abesto2" name="Abesto" type='number' label="" variant="standard" readOnly  value={x.Lines_details[1].Abesto} /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab2" name="Lab" type='number' label="" variant="standard" readOnly  value={x.Lines_details[1].Fuel}  /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField  inputProps={{min: 0, style: { textAlign: 'center' }}} id="War2" name="War" type='text' label="" variant="standard"  readOnly  value={x.Lines_details[1].Rental} /></td>
                    <td style={{verticalAlign: 'middle' }} className='text-center' >{((x.Lines_details[1].Cost ?  parseFloat(x.Lines_details[1].Cost) * parseFloat(x.Lines_details[1].Ton) : 0) + (x.Lines_details[1].Otro ? parseFloat(x.Lines_details[1].Otro) : 0 )+ (x.Lines_details[1].Abesto ? parseFloat(x.Lines_details[1].Abesto) : 0 ) + (x.Lines_details[1].Fuel ? parseFloat(x.Lines_details[1].Fuel) : 0 ) + (x.Lines_details[0].Rental ? parseFloat(x.Lines_details[1].Rental) : 0 )).toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td style={{verticalAlign: 'middle' }} className='text-center' >3</td>
                    <td ><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des3" name="Des" type='text' label="" variant="standard"           readOnly  value={x.Lines_details[2].Des}  /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Ton3" name="Ton" type='number' label="" variant="standard" readOnly  value={x.Lines_details[2].Ton}  /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri3" name="Pri" type='number' label="" variant="standard" readOnly  value={x.Lines_details[2].Cost}  /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Otro3" name="Otro" type='number' label="" variant="standard" readOnly  value={x.Lines_details[2].Otro}  /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Abesto3" name="Abesto" type='number' label="" variant="standard" readOnly  value={x.Lines_details[2].Abesto} /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab3" name="Lab" type='number' label="" variant="standard" readOnly  value={x.Lines_details[2].Fuel}  /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War3" name="War" type='text' label="" variant="standard"   readOnly  value={x.Lines_details[2].Rental}  /></td>
                    <td style={{verticalAlign: 'middle' }} className='text-center'>{((x.Lines_details[2].Cost ?  parseFloat(x.Lines_details[2].Cost) * parseFloat(x.Lines_details[2].Ton) : 0) + (x.Lines_details[2].Otro ? parseFloat(x.Lines_details[2].Otro) : 0 )+ (x.Lines_details[2].Abesto ? parseFloat(x.Lines_details[2].Abesto) : 0 ) + (x.Lines_details[2].Fuel ? parseFloat(x.Lines_details[2].Fuel) : 0 ) + (x.Lines_details[0].Rental ? parseFloat(x.Lines_details[2].Rental) : 0 )).toFixed(2)}</td>
    
                  </tr> 
                 
                    <tr >
                    <td style={{verticalAlign: 'middle' }} className='text-center'>4</td>
                    <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des4" name="Des" type='text' label="" variant="standard"            readOnly  value={x.Lines_details[3].Des}     /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Ton4" name="Ton" type='number' label="" variant="standard" readOnly  value={x.Lines_details[3].Ton}     /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri4" name="Pri" type='number' label="" variant="standard" readOnly  value={x.Lines_details[3].Cost}     /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Otro4" name="Otro" type='number' label="" variant="standard" readOnly  value={x.Lines_details[3].Otro}  /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Abesto4" name="Abesto" type='number' label="" variant="standard" readOnly  value={x.Lines_details[3].Abesto} /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab4" name="Lab" type='number' label="" variant="standard" readOnly  value={x.Lines_details[3].Fuel}     /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War4" name="War" type='text' label="" variant="standard"   readOnly  value={x.Lines_details[3].Rental}     /></td>
                    <td style={{verticalAlign: 'middle' }} className='text-center'>{((x.Lines_details[3].Cost ?  parseFloat(x.Lines_details[3].Cost) * parseFloat(x.Lines_details[3].Ton) : 0) + (x.Lines_details[3].Otro ? parseFloat(x.Lines_details[3].Otro) : 0 )+ (x.Lines_details[3].Abesto ? parseFloat(x.Lines_details[3].Abesto) : 0 ) + (x.Lines_details[3].Fuel ? parseFloat(x.Lines_details[3].Fuel) : 0 ) + (x.Lines_details[0].Rental ? parseFloat(x.Lines_details[3].Rental) : 0 )).toFixed(2)}</td>
                  </tr> 
                  
                  <tr >
                    <td style={{verticalAlign: 'middle' }} className='text-center'>5</td>
                    <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des5" name="Des" type='text' label="" variant="standard"             readOnly  value={x.Lines_details[4].Des}    /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Ton5" name="Ton" type='number' label="" variant="standard"  readOnly  value={x.Lines_details[4].Ton}    /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri5" name="Pri" type='number' label="" variant="standard"  readOnly  value={x.Lines_details[4].Cost}    /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Otro5" name="Otro" type='number' label="" variant="standard" readOnly  value={x.Lines_details[4].Otro}  /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Abesto5" name="Abesto" type='number' label="" variant="standard" readOnly  value={x.Lines_details[4].Abesto} /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab5" name="Lab" type='number' label="" variant="standard"  readOnly  value={x.Lines_details[4].Fuel}    /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War5" name="War" type='text' label="" variant="standard"    readOnly  value={x.Lines_details[4].Rental}    /></td>
                    <td style={{verticalAlign: 'middle' }} className='text-center'>{((x.Lines_details[4].Cost ?  parseFloat(x.Lines_details[4].Cost) * parseFloat(x.Lines_details[4].Ton) : 0) + (x.Lines_details[4].Otro ? parseFloat(x.Lines_details[4].Otro) : 0 )+ (x.Lines_details[4].Abesto ? parseFloat(x.Lines_details[4].Abesto) : 0 ) + (x.Lines_details[4].Fuel ? parseFloat(x.Lines_details[4].Fuel) : 0 ) + (x.Lines_details[0].Rental ? parseFloat(x.Lines_details[4].Rental) : 0 )).toFixed(2)}</td>
                  </tr> 
                 
                   <tr >
                    <td style={{verticalAlign: 'middle' }} className='text-center'>6</td>
                    <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des6" name="Des" type='text' label="" variant="standard"             readOnly  value={x.Lines_details[5].Des}   /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Ton6" name="Ton" type='number' label="" variant="standard"  readOnly  value={x.Lines_details[5].Ton}   /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri6" name="Pri" type='number' label="" variant="standard"  readOnly  value={x.Lines_details[5].Cost}   /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Otro6" name="Otro" type='number' label="" variant="standard" readOnly  value={x.Lines_details[5].Otro}  /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Abesto6" name="Abesto" type='number' label="" variant="standard" readOnly  value={x.Lines_details[5].Abesto} /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab6" name="Lab" type='number' label="" variant="standard"  readOnly  value={x.Lines_details[5].Fuel}   /></td>
                    <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War6" name="War" type='text' label="" variant="standard"    readOnly  value={x.Lines_details[5].Rental}   /></td>
                    <td style={{verticalAlign: 'middle' }} className='text-center'>{((x.Lines_details[5].Cost ?  parseFloat(x.Lines_details[5].Cost) * parseFloat(x.Lines_details[5].Ton) : 0) + (x.Lines_details[5].Otro ? parseFloat(x.Lines_details[5].Otro) : 0 )+ (x.Lines_details[5].Abesto ? parseFloat(x.Lines_details[5].Abesto) : 0 ) + (x.Lines_details[5].Fuel ? parseFloat(x.Lines_details[5].Fuel) : 0 ) + (x.Lines_details[0].Rental ? parseFloat(x.Lines_details[5].Rental) : 0 )).toFixed(2)}</td>
                  </tr>                     
                  <tr>
                    <td className='text-center' style={{borderColor:'white'}} ></td>
                    <td style={{borderColor:'white'}}></td>
                    <td style={{borderColor:'white'}}></td>
                    <td style={{borderColor:'white'}}></td>
                    <td style={{borderColor:'white'}}></td>
                    <td style={{borderColor:'white'}}></td>
                    <td style={{borderBottomColor:'white'}}></td>
                    <td style={{borderColor:'black'}} className='text-left' ><h6><strong>Total $</strong></h6></td>
                    <td style={{borderColor:'black'}} className='text-center' >
                        <h6>
                        <strong>
                        {
                           (((x.Lines_details[0].Cost ?  parseFloat(x.Lines_details[0].Cost) * parseFloat(x.Lines_details[0].Ton) : 0) + (x.Lines_details[0].Otro ? parseFloat(x.Lines_details[0].Otro) : 0 )+ (x.Lines_details[0].Abesto ? parseFloat(x.Lines_details[0].Abesto) : 0 ) + (x.Lines_details[0].Fuel ? parseFloat(x.Lines_details[0].Fuel) : 0 ) + (x.Lines_details[0].Rental ? parseFloat(x.Lines_details[0].Rental) : 0 )) + 
                            ((x.Lines_details[1].Cost ?  parseFloat(x.Lines_details[1].Cost) * parseFloat(x.Lines_details[1].Ton) : 0) + (x.Lines_details[1].Otro ? parseFloat(x.Lines_details[1].Otro) : 0 )+ (x.Lines_details[1].Abesto ? parseFloat(x.Lines_details[1].Abesto) : 0 ) + (x.Lines_details[1].Fuel ? parseFloat(x.Lines_details[1].Fuel) : 0 ) + (x.Lines_details[1].Rental ? parseFloat(x.Lines_details[1].Rental) : 0 )) +
                            ((x.Lines_details[2].Cost ?  parseFloat(x.Lines_details[2].Cost) * parseFloat(x.Lines_details[2].Ton) : 0) + (x.Lines_details[2].Otro ? parseFloat(x.Lines_details[2].Otro) : 0 )+ (x.Lines_details[2].Abesto ? parseFloat(x.Lines_details[2].Abesto) : 0 ) + (x.Lines_details[2].Fuel ? parseFloat(x.Lines_details[2].Fuel) : 0 ) + (x.Lines_details[2].Rental ? parseFloat(x.Lines_details[2].Rental) : 0 )) + 
                            ((x.Lines_details[3].Cost ?  parseFloat(x.Lines_details[3].Cost) * parseFloat(x.Lines_details[3].Ton) : 0) + (x.Lines_details[3].Otro ? parseFloat(x.Lines_details[3].Otro) : 0 )+ (x.Lines_details[3].Abesto ? parseFloat(x.Lines_details[3].Abesto) : 0 ) + (x.Lines_details[3].Fuel ? parseFloat(x.Lines_details[3].Fuel) : 0 ) + (x.Lines_details[3].Rental ? parseFloat(x.Lines_details[3].Rental) : 0 )) +
                            ((x.Lines_details[4].Cost ?  parseFloat(x.Lines_details[4].Cost) * parseFloat(x.Lines_details[4].Ton) : 0) + (x.Lines_details[4].Otro ? parseFloat(x.Lines_details[4].Otro) : 0 )+ (x.Lines_details[4].Abesto ? parseFloat(x.Lines_details[4].Abesto) : 0 ) + (x.Lines_details[4].Fuel ? parseFloat(x.Lines_details[4].Fuel) : 0 ) + (x.Lines_details[4].Rental ? parseFloat(x.Lines_details[4].Rental) : 0 )) + 
                            ((x.Lines_details[5].Cost ?  parseFloat(x.Lines_details[5].Cost) * parseFloat(x.Lines_details[5].Ton) : 0) + (x.Lines_details[5].Otro ? parseFloat(x.Lines_details[5].Otro) : 0 )+ (x.Lines_details[5].Abesto ? parseFloat(x.Lines_details[5].Abesto) : 0 ) + (x.Lines_details[5].Fuel ? parseFloat(x.Lines_details[5].Fuel) : 0 ) + (x.Lines_details[5].Rental ? parseFloat(x.Lines_details[5].Rental) : 0 ))).toFixed(2)
    
                        }
                        </strong>
                        </h6>
                    </td>
                  </tr>   
                  <tr>
                    <td className='text-center' style={{borderColor:'white'}}></td>
                    <td style={{borderColor:'white'}} ></td>
                    <td style={{borderColor:'white'}} ></td>
                    <td style={{borderColor:'white'}} ></td>
                    <td style={{borderColor:'white'}} ></td>
                    <td style={{borderColor:'white'}} ></td>
                    <td style={{borderBottomColor:'white'}} ></td>
                    <td style={{borderColor:'black'}} className='text-left'><h6><strong>Paid Amount $</strong></h6></td>
                    <td style={{borderColor:'black'}} className='text-center' ><strong>
                      <TextField inputProps={{min: 0, style: { textAlign: 'center' }}}  
                      id="amo" type='number' label="" 
                      name="amo"
                      placeholder="0.00"
                      variant="standard"  
                      readOnly  value={"0.00"}
                      /></strong></td>
                  </tr> 
                  <tr>
                    <td className='text-center' style={{borderColor:'white'}}></td>
                    <td style={{borderColor:'white'}}>
                    <TextField  id="Payment" name="Payment" type='text' label="Payment methodo" variant="standard" readOnly  value={x.Payment} />
                        {/* <Autocomplete
                          id="idpayment"
                          options={ dbdata.payment}
                          getOptionLabel={(option) => option.title}
                          style={{ width: 160 }}
                          renderInput={(params) => <TextField {...params} id="Payment" name="Payment" type='text' label="Payment methodo" variant="standard" readOnly  value={x.Payment} />
                        }
                        />  */}
                        </td>
                    <td style={{borderColor:'white'}}></td>
                    <td style={{borderColor:'white'}}></td>
                    <td style={{borderColor:'white'}}></td>
                    <td style={{borderColor:'white'}}></td>
                    <td style={{borderBottomColor:'white'}}></td>
                    <td style={{borderColor:'black', verticalAlign:'middle'}} className='text-left'><h6><strong>Balance Due $</strong></h6></td>
                    <td style={{borderColor:'black', verticalAlign:'middle'}} className='text-center' ><strong>
                    <TextField inputProps={{min: 0, style: { textAlign: 'center', fontWeight: 'bold', fontSize: "large" }}} id="Balance" name="Balance"  type='text' label="" variant="standard" 
                    readOnly  value= 
                    {
                    ((((x.Lines_details[0].Cost ?  parseFloat(x.Lines_details[0].Cost) * parseFloat(x.Lines_details[0].Ton) : 0) + (x.Lines_details[0].Otro ? parseFloat(x.Lines_details[0].Otro) : 0 )+ (x.Lines_details[0].Abesto ? parseFloat(x.Lines_details[0].Abesto) : 0 ) + (x.Lines_details[0].Fuel ? parseFloat(x.Lines_details[0].Fuel) : 0 ) + (x.Lines_details[0].Rental ? parseFloat(x.Lines_details[0].Rental) : 0 )) + 
                      ((x.Lines_details[1].Cost ?  parseFloat(x.Lines_details[1].Cost) * parseFloat(x.Lines_details[1].Ton) : 0) + (x.Lines_details[1].Otro ? parseFloat(x.Lines_details[1].Otro) : 0 )+ (x.Lines_details[1].Abesto ? parseFloat(x.Lines_details[1].Abesto) : 0 ) + (x.Lines_details[1].Fuel ? parseFloat(x.Lines_details[1].Fuel) : 0 ) + (x.Lines_details[1].Rental ? parseFloat(x.Lines_details[1].Rental) : 0 )) +
                      ((x.Lines_details[2].Cost ?  parseFloat(x.Lines_details[2].Cost) * parseFloat(x.Lines_details[2].Ton) : 0) + (x.Lines_details[2].Otro ? parseFloat(x.Lines_details[2].Otro) : 0 )+ (x.Lines_details[2].Abesto ? parseFloat(x.Lines_details[2].Abesto) : 0 ) + (x.Lines_details[2].Fuel ? parseFloat(x.Lines_details[2].Fuel) : 0 ) + (x.Lines_details[2].Rental ? parseFloat(x.Lines_details[2].Rental) : 0 )) + 
                      ((x.Lines_details[3].Cost ?  parseFloat(x.Lines_details[3].Cost) * parseFloat(x.Lines_details[3].Ton) : 0) + (x.Lines_details[3].Otro ? parseFloat(x.Lines_details[3].Otro) : 0 )+ (x.Lines_details[3].Abesto ? parseFloat(x.Lines_details[3].Abesto) : 0 ) + (x.Lines_details[3].Fuel ? parseFloat(x.Lines_details[3].Fuel) : 0 ) + (x.Lines_details[3].Rental ? parseFloat(x.Lines_details[3].Rental) : 0 )) + 
                      ((x.Lines_details[4].Cost ?  parseFloat(x.Lines_details[4].Cost) * parseFloat(x.Lines_details[4].Ton) : 0) + (x.Lines_details[4].Otro ? parseFloat(x.Lines_details[4].Otro) : 0 )+ (x.Lines_details[4].Abesto ? parseFloat(x.Lines_details[4].Abesto) : 0 ) + (x.Lines_details[4].Fuel ? parseFloat(x.Lines_details[4].Fuel) : 0 ) + (x.Lines_details[4].Rental ? parseFloat(x.Lines_details[4].Rental) : 0 )) + 
                      ((x.Lines_details[5].Cost ?  parseFloat(x.Lines_details[5].Cost) * parseFloat(x.Lines_details[5].Ton) : 0) + (x.Lines_details[5].Otro ? parseFloat(x.Lines_details[5].Otro) : 0 )+ (x.Lines_details[5].Abesto ? parseFloat(x.Lines_details[5].Abesto) : 0 ) + (x.Lines_details[5].Fuel ? parseFloat(x.Lines_details[5].Fuel) : 0 ) + (x.Lines_details[5].Rental ? parseFloat(x.Lines_details[5].Rental) : 0 ))) ).toFixed(2)
    
                  }
                    /></strong>                  
                    </td>
                  </tr>  
                </tbody>
                </table>
            </Row>       
            <Row className='mb-2'>
                <Col  className='text-center'>
                    <h5>Customer signature</h5>
                </Col>
                <Col className='text-center'>
                    <h5>{businessname}</h5>                
                </Col>
            </Row>    
            <Row className='mb-5'>           
                <Col md={12} className='text-left'>
                    <h6>NOTES / SPECIAL REQUEST(S):</h6>
                    {/* <textarea  
                      style={{ fontSizeAdjust:15, width:'100%', height:'150px',  padding: "12px 20px", borderColor:'white',  fontSize: "16px", resize: "none" }}
                       id="Recommend" 
                       name="Recommend" 
                       type='text' 
                    />    */}
                    <textarea  
                      style={{ borderColor:'white', width:'100%',  height:'100px', resize:'vertical'}}
                       id="Recommend" 
                       name="Recommend" 
                       type='text' 
                       readOnly  value={x.Note}
                    />               
                      
                </Col>
            </Row> 
            <Row>
                <Col  className='text-center' >
                    <span><strong>{bemail}</strong></span>
                </Col>
                <Col  className='text-center'>
                    <h5 ><strong>Thanks for choosing us.</strong></h5>
                </Col>
                <Col  >
                <Row>
                <Col>                  
                </Col>
                <Col>
                    <Button    
                           onClick={print}  
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="primary"
                            startIcon={<PrintIcon />}                  
                           >
                            Print
                       </Button>
                </Col>

              </Row>
                </Col>
            </Row>
            </form>
          </Container>
            )
        }
        </> 
        
    )
}

export default SearchInvoice