import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Axios from 'axios'
import { Link } from 'react-router-dom';
import './InvoiceLast.css'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Divider } from '@material-ui/core';
import { Button } from '@material-ui/core';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@material-ui/icons/Save';
import dbdata from "../db/dbdata.json"
// import dbMakes from "../db/dbMakes.json"
// import dbModels from "../db/dbModels.json"
import Getidinvoice from '../components/Getidinvoice';
import {businessname, bstreet, bcity, bemail, bphone} from '../models/European'
import logo from '../assets/image/logo.png'

const authentication = e => {    

 
    e.preventDefault()
    const form = e.target    

   const data = {
    "Idinvoice": form.Idinvoice.value,
    "Name": form.Name.value,
    "Phone": form.Phone.value,
    "Date": form.Date.value,
    "Balance": form.Balance.value,
    "Payment": form.Payment.value,
    "Ddate": form.Ddate.value,
    "Note": form.Recommend.value,
    "Address": form.Address.value,
    "Otrofield": form.Otrofield.value,
      "Lines_details": [         
          {
              "Des": form.Des1.value,
              "Ton": form.Qua1.value,
              "Cost": form.Pri1.value,
              "Otro": form.Otro1.value,
              "Abesto": form.Abesto1.value,
              "Fuel":form.Lab1.value,
              "Rental": form.War1.value
          },
          { 
              "Des": form.Des2.value,
              "Ton": form.Qua2.value,
              "Cost": form.Pri2.value,
              "Otro": form.Otro2.value,
              "Abesto": form.Abesto2.value,
              "Fuel":form.Lab2.value,
              "Rental": form.War2.value
          },
          {
            "Des": form.Des3.value,
            "Ton": form.Qua3.value,
            "Cost": form.Pri3.value,
            "Otro": form.Otro3.value,
            "Abesto": form.Abesto3.value,
            "Fuel": form.Lab3.value,
            "Rental": form.War3.value
          },
          {
            "Des": form.Des4.value,
            "Ton": form.Qua4.value,
            "Cost": form.Pri4.value,
            "Otro": form.Otro4.value,
            "Abesto": form.Abesto4.value,
            "Fuel": form.Lab4.value,
            "Rental": form.War4.value
          },
          {
            "Des": form.Des5.value,
            "Ton": form.Qua5.value,
            "Cost": form.Pri5.value,
            "Otro": form.Otro5.value,
            "Abesto": form.Abesto5.value,
            "Fuel": form.Lab5.value,
            "Rental": form.War5.value
          },
          {
            "Des": form.Des6.value,
            "Ton": form.Qua6.value,
            "Cost": form.Pri6.value,
            "Otro": form.Otro6.value,
            "Abesto": form.Abesto6.value,
            "Fuel": form.Lab6.value,
            "Rental": form.War6.value
          }
      ]
  }  

  Axios.post(process.env.REACT_APP_CREATEINVOICE, data)
    .then(r => {
      localStorage.setItem('token', r.data.token)
      window.location = "/"
    }).then(() => alert('The Invoice has been succesfully saved'))
    .catch(e => {
      alert('error al iniciar sesion')
    } )
  
  }


  

class InvoiceLast extends React.Component  { 
    constructor(props) {
        super(props);
        this.state = { pri1: 0 };
        this.state = { lab1: 0 };
        this.state = { otro1: 0 };
        this.state = { abesto1: 0 };
        this.state = { qua1: 0 };
        this.state = { war1: 0 };
        this.state = { pri2: 0 };
        this.state = { lab2: 0 };
        this.state = { otro2: 0 };
        this.state = { abesto2: 0 };
        this.state = { qua2: 0 };
        this.state = { war2: 0 };
        this.state = { pri3: 0 };
        this.state = { lab3: 0 };
        this.state = { otro3: 0 };
        this.state = { abesto3: 0 };
        this.state = { qua3: 0 };
        this.state = { war3: 0 };
        this.state = { pri4: 0 };        
        this.state = { lab4: 0 };
        this.state = { otro4: 0 };
        this.state = { abesto4: 0 };
        this.state = { qua4: 0 };
        this.state = { war4: 0 };
        this.state = { pri5: 0 };        
        this.state = { lab5: 0 };
        this.state = { otro5: 0 };
        this.state = { abesto5: 0 };
        this.state = { qua5: 0 };
        this.state = { war5: 0 };
        this.state = { pri6: 0 };        
        this.state = { lab6: 0 };
        this.state = { otro6: 0 };
        this.state = { abesto6: 0 };
        this.state = { qua6: 0 };
        this.state = { war6: 0 };
        this.state = { amo: 0 };
        this.state = { total: 0 };
        this.state = { otrofield: '' };
        // this.state = { dbt: false };
        // this.state = { cas: false };
      }

    
      
     
      myChangeHandlerpri1 = (event) => {
        this.setState({pri1: event.target.value});    
      }
      myChangeHandlerlab1 = (event) => {
        this.setState({lab1: event.target.value});    
      }
      myChangeHandlerotro1 = (event) => {
        this.setState({otro1: event.target.value});    
      }
      myChangeHandlerabesto1 = (event) => {
        this.setState({abesto1: event.target.value});    
      }
      myChangeHandlerqua1 = (event) => {
        this.setState({qua1: event.target.value});    
      }     
      myChangeHandlerwar1 = (event) => {
        this.setState({war1: event.target.value});    
      }     
      myChangeHandlerpri2 = (event) => {
        this.setState({pri2: event.target.value});  
      } 
        myChangeHandlerotro2 = (event) => {
          this.setState({otro2: event.target.value});    
        }
        myChangeHandlerabesto2 = (event) => {
          this.setState({abesto2: event.target.value});    
        } 
      myChangeHandlerlab2 = (event) => {
        this.setState({lab2: event.target.value});    
      }
      myChangeHandlerqua2 = (event) => {
        this.setState({qua2: event.target.value});    
      }
      myChangeHandlerwar2 = (event) => {
        this.setState({war2: event.target.value});    
      } 
      myChangeHandlerpri3 = (event) => {
        this.setState({pri3: event.target.value});  
      }

        myChangeHandlerotro3 = (event) => {
          this.setState({otro3: event.target.value});    
        }
        myChangeHandlerabesto3 = (event) => {
          this.setState({abesto3: event.target.value});    
        } 
      myChangeHandlerlab3 = (event) => {
        this.setState({lab3: event.target.value});    
      }
      myChangeHandlerqua3 = (event) => {
        this.setState({qua3: event.target.value});    
      }
      myChangeHandlerwar3 = (event) => {
        this.setState({war3: event.target.value});    
      } 
      myChangeHandlerpri4 = (event) => {
        this.setState({pri4: event.target.value});   
      } 
        myChangeHandlerotro4 = (event) => {
          this.setState({otro4: event.target.value});    
        }
        myChangeHandlerabesto4 = (event) => {
          this.setState({abesto4: event.target.value});    
        } 
      myChangeHandlerotro4 = (event) => {
          this.setState({otro4: event.target.value});    
        }
        myChangeHandlerabesto4 = (event) => {
          this.setState({abesto4: event.target.value});    
        }
      myChangeHandlerlab4 = (event) => {
        this.setState({lab4: event.target.value});    
      }
      myChangeHandlerqua4 = (event) => {
        this.setState({qua4: event.target.value});    
      }
      myChangeHandlerwar4 = (event) => {
        this.setState({war4: event.target.value});    
      } 
      myChangeHandlerpri5 = (event) => {
        this.setState({pri5: event.target.value});    
      }
      myChangeHandlerotro5 = (event) => {
        this.setState({otro5: event.target.value});    
      }
      myChangeHandlerabesto5 = (event) => {
        this.setState({abesto5: event.target.value});    
      } 
      myChangeHandlerlab5 = (event) => {
        this.setState({lab5: event.target.value});    
      }
      myChangeHandlerqua5 = (event) => {
        this.setState({qua5: event.target.value});    
      }
      myChangeHandlerwar5 = (event) => {
        this.setState({war5: event.target.value});    
      } 
      myChangeHandlerpri6 = (event) => {
        this.setState({pri6: event.target.value});    
      }
      myChangeHandlerotro6 = (event) => {
        this.setState({otro6: event.target.value});    
      }
      myChangeHandlerabesto6 = (event) => {
        this.setState({abesto6: event.target.value});    
      } 
      myChangeHandlerlab6 = (event) => {
        this.setState({lab6: event.target.value});    
      }
      myChangeHandlerqua6 = (event) => {
        this.setState({qua6: event.target.value});    
      }
      myChangeHandlerwar6 = (event) => {
        this.setState({war6: event.target.value});    
      } 
      myChangeHandleramo = (event) => {
        this.setState({amo: event.target.value});    
      }
      myChangeHandlerotrofield = (event) => {
        this.setState({otrofield: event.target.value});    
      }
      myChangeHandlertotal = (event) => {
        this.setState( 
          (((this.state.pri1 ? parseFloat(this.state.pri1) * parseFloat(this.state.qua1) : 0) + (this.state.otro1 ? parseFloat(this.state.otro1) : 0 ) + (this.state.abesto1 ? parseFloat(this.state.abesto1) : 0 )+ (this.state.lab1 ? parseFloat(this.state.lab1) : 0 ) + (this.state.war1 ? parseFloat(this.state.war1) : 0 )) + 
          ((this.state.pri2 ?  parseFloat(this.state.pri2) * parseFloat(this.state.qua2) : 0) + (this.state.otro2 ? parseFloat(this.state.otro2) : 0 ) + (this.state.abesto2 ? parseFloat(this.state.abesto2) : 0 )+ (this.state.lab2 ? parseFloat(this.state.lab2) : 0 ) + (this.state.war2 ? parseFloat(this.state.war2) : 0 )) +
          ((this.state.pri3 ?  parseFloat(this.state.pri3) * parseFloat(this.state.qua3) : 0) + (this.state.otro3 ? parseFloat(this.state.otro3) : 0 ) + (this.state.abesto3 ? parseFloat(this.state.abesto3) : 0 )+ (this.state.lab3 ? parseFloat(this.state.lab3) : 0 ) + (this.state.war3 ? parseFloat(this.state.war3) : 0 )) + 
          ((this.state.pri4 ?  parseFloat(this.state.pri4) * parseFloat(this.state.qua4) : 0) + (this.state.otro4 ? parseFloat(this.state.otro4) : 0 ) + (this.state.abesto4 ? parseFloat(this.state.abesto4) : 0 )+ (this.state.lab4 ? parseFloat(this.state.lab4) : 0 ) + (this.state.war4 ? parseFloat(this.state.war4) : 0 )) +
          ((this.state.pri5 ?  parseFloat(this.state.pri5) * parseFloat(this.state.qua5) : 0) + (this.state.otro5 ? parseFloat(this.state.otro5) : 0 ) + (this.state.abesto5 ? parseFloat(this.state.abesto5) : 0 )+ (this.state.lab5 ? parseFloat(this.state.lab5) : 0 ) + (this.state.war5 ? parseFloat(this.state.war5) : 0 )) + 
          ((this.state.pri6 ?  parseFloat(this.state.pri6) * parseFloat(this.state.qua6) : 0) + (this.state.otro6 ? parseFloat(this.state.otro6) : 0 ) + (this.state.abesto6 ? parseFloat(this.state.abesto6) : 0 )+ (this.state.lab6 ? parseFloat(this.state.lab6) : 0 ) + (this.state.war6 ? parseFloat(this.state.war6) : 0 ))).toFixed(2)

      );    
      }
      print(){
        window.print();
    }

    render(){
    return(
        <>
        <Container style={{marginTop:'40px'}}>   
            <form onSubmit={authentication.bind()}>
        <Row className='mb-3'>
            <Col sm={3} className='text-center'> 
            <div >
                <Link to='/account'>
                 <img               
                style={{width: '300px', height:200}}
                src={logo}
               alt='..'  /> 
                </Link>
            </div>
             </Col>
            <Col sm={6} className="text-center" > 
            <h2 className="text-center">{businessname}</h2>
                <p className="text-center">{bstreet}<br/>
                {bcity}<br/>{bphone}</p>
            </Col>
            <Col sm={3} className="text-center"> 
            <h3 >Invoice No:</h3>
            <Getidinvoice />
            </Col>
        </Row>
        <Divider variant="middle" />
        <Divider className='mb-1' variant="middle" />
        <Row className='mb-2'>  
            <Col>
            <h5 style={{marginTop:12}}>Bill To:</h5>
            <TextField fullWidth  id="Name" name='Name' type='text' label="Full Name" variant="standard" />
            </Col>      
            <Col>
            <h5 style={{marginTop:12}}>Phone:</h5>
            <TextField fullWidth  id="Phone" name="Phone" type='number' label="Phone Number" variant="standard" />
            </Col>   
            <Col>
            <h5>Date:</h5>
            <TextField  style={{ width:220, marginTop:17 }}  id="Date" name="Date" type='date'  variant="standard" />
            </Col>               
        </Row>
        <Divider variant="middle" />
        <h4>Project information</h4>        
        <Row className='mb-3'>     
              <Col>
              <h5 style={{ fontSize:17 }}>Address:</h5>
              <TextField fullWidth style={{ width:500, marginTop:15, maxWidth:'100%' }} id="Address" name='Address' type='text' label="" variant="standard" />
              </Col>
              <Col>
              <h5 style={{ fontSize:17 }}>Due Date:</h5> 
              <TextField fullWidth style={{ width:220, marginTop:17 }} size='small'  id="Ddate" name="Ddate" type='datetime-local'  variant="standard" />
              </Col>                   
        </Row>
        <Row className='mb-5' >
        <table className="table  table-sm ">
            <thead>
              <tr className='text-center'>
                <th style={{width:'20px', borderBottomColor:'black', verticalAlign:'middle'}}>#</th>
                <th style={{width:'330px', borderBottomColor:'black', verticalAlign:'middle'}}>Service Description</th> 
                <th style={{width:'80px', borderBottomColor:'black', verticalAlign:'middle'}}>Tons</th>
                <th style={{width:'80px', borderBottomColor:'black', verticalAlign:'middle'}}>Cost x Tons $</th>
                <th style={{width:'80px', borderBottomColor:'black', verticalAlign:'middle'}}><TextField   inputProps={{ disableUnderline: true,  textAlign: 'center'}} id="Otrofiels" name="Otrofield" type='text' label="" variant="standard" onChange={this.myChangeHandlerotrofield} /></th>
                <th style={{width:'80px', borderBottomColor:'black', verticalAlign:'middle'}}>Asbesto</th>
                <th style={{width:'80px', borderBottomColor:'black', verticalAlign:'middle'}}>Fuel $</th>
                <th style={{width:'120px', borderBottomColor:'black', verticalAlign:'middle'}}>Trailer rental $</th>
                <th style={{width:'100px', borderBottomColor:'black', verticalAlign:'middle'}}>Total $</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center'>1</td>
                <td><TextField multiline  fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}} id="Des1" name="Des" type='text' label="" variant="standard" /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua1" name="Qua" type='decimal' label="" variant="standard" onChange={this.myChangeHandlerqua1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center'}}} id="Pri1" name="Pri" type='number' label="" variant="standard" onChange={this.myChangeHandlerpri1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Otro1" name="Otro" type='number' label="" variant="standard" onChange={this.myChangeHandlerotro1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Abesto1" name="Abesto" type='number' label="" variant="standard" onChange={this.myChangeHandlerabesto1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab1" name="Lab" type='number' label="" variant="standard" onChange={this.myChangeHandlerlab1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War1" name="War" type='number' label="" variant="standard" onChange={this.myChangeHandlerwar1} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center' >{((this.state.pri1 ?  parseFloat(this.state.pri1) * parseFloat(this.state.qua1) : 0) + (this.state.otro1 ? parseFloat(this.state.otro1) : 0 )+ (this.state.abesto1 ? parseFloat(this.state.abesto1) : 0 ) + (this.state.lab1 ? parseFloat(this.state.lab1) : 0 ) + (this.state.war1 ? parseFloat(this.state.war1) : 0 )).toFixed(2)}</td>
              </tr>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center'>2</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des2" name="Des" type='text' label="" variant="standard" /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua2" name="Qua" type='decimal' label="" variant="standard" onChange={this.myChangeHandlerqua2} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri2" name="Pri" type='number' label="" variant="standard" onChange={this.myChangeHandlerpri2} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Otro2" name="Otro" type='number' label="" variant="standard" onChange={this.myChangeHandlerotro2} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Abesto2" name="Abesto" type='number' label="" variant="standard" onChange={this.myChangeHandlerabesto2} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab2" name="Lab" type='number' label="" variant="standard" onChange={this.myChangeHandlerlab2} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War2" name="War" type='number' label="" variant="standard" onChange={this.myChangeHandlerwar2} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center' >{((this.state.pri2 ?  parseFloat(this.state.pri2) * parseFloat(this.state.qua2) : 0) + (this.state.otro2 ? parseFloat(this.state.otro2) : 0 )+ (this.state.abesto2 ? parseFloat(this.state.abesto2) : 0 ) + (this.state.lab2 ? parseFloat(this.state.lab2) : 0 ) + (this.state.war2 ? parseFloat(this.state.war2) : 0 )).toFixed(2)}</td>
              </tr>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center' >3</td>
                <td ><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des3" name="Des" type='text' label="" variant="standard" /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua3" name="Qua" type='decimal' label="" variant="standard" onChange={this.myChangeHandlerqua3} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri3" name="Pri" type='number' label="" variant="standard" onChange={this.myChangeHandlerpri3} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Otro3" name="Otro" type='number' label="" variant="standard" onChange={this.myChangeHandlerotro3} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Abesto3" name="Abesto" type='number' label="" variant="standard" onChange={this.myChangeHandlerabesto3} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab3" name="Lab" type='number' label="" variant="standard"  onChange={this.myChangeHandlerlab3}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War3" name="War" type='number' label="" variant="standard"  onChange={this.myChangeHandlerwar3}/></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((this.state.pri3 ?  parseFloat(this.state.pri3) * parseFloat(this.state.qua3) : 0) + (this.state.otro3 ? parseFloat(this.state.otro3) : 0 )+ (this.state.abesto3 ? parseFloat(this.state.abesto3) : 0 ) + (this.state.lab3 ? parseFloat(this.state.lab3) : 0 ) + (this.state.war3 ? parseFloat(this.state.war3) : 0 )).toFixed(2)}</td>

              </tr> 
             
                <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>4</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des4" name="Des" type='text' label="" variant="standard"  /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua4" name="Qua" type='decimal' label="" variant="standard" onChange={this.myChangeHandlerqua4} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri4" name="Pri" type='number' label="" variant="standard"  onChange={this.myChangeHandlerpri4} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Otro4" name="Otro" type='number' label="" variant="standard" onChange={this.myChangeHandlerotro4} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Abesto4" name="Abesto" type='number' label="" variant="standard" onChange={this.myChangeHandlerabesto4} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab4" name="Lab" type='number' label="" variant="standard"   onChange={this.myChangeHandlerlab4}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War4" name="War" type='number' label="" variant="standard"   onChange={this.myChangeHandlerwar4}/></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((this.state.pri4 ?  parseInt(this.state.pri4) * parseFloat(this.state.qua4) : 0) + (this.state.otro4 ? parseFloat(this.state.otro4) : 0 )+ (this.state.abesto4 ? parseFloat(this.state.abesto4) : 0 ) + (this.state.lab4 ? parseFloat(this.state.lab4) : 0 ) + (this.state.war4 ? parseFloat(this.state.war4) : 0 )).toFixed(2)}</td>
              </tr> 
              
              <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>5</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des5" name="Des" type='text' label="" variant="standard"  /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua5" name="Qua" type='decimal' label="" variant="standard" onChange={this.myChangeHandlerqua5} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri5" name="Pri" type='number' label="" variant="standard"  onChange={this.myChangeHandlerpri5} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Otro5" name="Otro" type='number' label="" variant="standard" onChange={this.myChangeHandlerotro5} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Abesto5" name="Abesto" type='number' label="" variant="standard" onChange={this.myChangeHandlerabesto5} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab5" name="Lab" type='number' label="" variant="standard"   onChange={this.myChangeHandlerlab5}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War5" name="War" type='number' label="" variant="standard"   onChange={this.myChangeHandlerwar5}/></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((this.state.pri5 ?  parseInt(this.state.pri5) * parseFloat(this.state.qua5) : 0) + (this.state.otro5 ? parseFloat(this.state.otro5) : 0 )+ (this.state.abesto5 ? parseFloat(this.state.abesto5) : 0 ) + (this.state.lab5 ? parseFloat(this.state.lab5) : 0 ) + (this.state.war5 ? parseFloat(this.state.war5) : 0 )).toFixed(2)}</td>
              </tr> 
             
               <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>6</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des6" name="Des" type='text' label="" variant="standard"  /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua6" name="Qua" type='decimal' label="" variant="standard" onChange={this.myChangeHandlerqua6} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri6" name="Pri" type='number' label="" variant="standard"  onChange={this.myChangeHandlerpri6} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Otro6" name="Otro" type='number' label="" variant="standard" onChange={this.myChangeHandlerotro6} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Abesto6" name="Abesto" type='number' label="" variant="standard" onChange={this.myChangeHandlerabesto6} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab6" name="Lab" type='number' label="" variant="standard"   onChange={this.myChangeHandlerlab6}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War6" name="War" type='number' label="" variant="standard"   onChange={this.myChangeHandlerwar6}/></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((this.state.pri6 ?  parseFloat(this.state.pri6) * parseFloat(this.state.qua6) : 0) + (this.state.otro6 ? parseFloat(this.state.otro6) : 0 )+ (this.state.abesto6 ? parseFloat(this.state.abesto6) : 0 ) + (this.state.lab6 ? parseFloat(this.state.lab6) : 0 ) + (this.state.war6 ? parseFloat(this.state.war6) : 0 )).toFixed(2)}</td>
              </tr>                     
              <tr>
                <td className='text-center' style={{borderColor:'white'}} ></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderBottomColor:'white'}}></td>
                <td style={{borderColor:'black'}} className='text-left' ><h6><strong>Total $</strong></h6></td>
                <td style={{borderColor:'black'}} className='text-center' >
                    <h6>
                    <strong >
                    {
                        (((this.state.pri1 ? parseFloat(this.state.pri1) * parseFloat(this.state.qua1) : 0) + (this.state.otro1 ? parseFloat(this.state.otro1) : 0 ) + (this.state.abesto1 ? parseFloat(this.state.abesto1) : 0 ) + (this.state.lab1 ? parseFloat(this.state.lab1) : 0 ) + (this.state.war1 ? parseFloat(this.state.war1) : 0 )) + 
                        ((this.state.pri2 ?  parseFloat(this.state.pri2) * parseFloat(this.state.qua2) : 0) + (this.state.otro2 ? parseFloat(this.state.otro2) : 0 ) + (this.state.abesto2 ? parseFloat(this.state.abesto2) : 0 ) + (this.state.lab2 ? parseFloat(this.state.lab2) : 0 ) + (this.state.war2 ? parseFloat(this.state.war2) : 0 )) +
                        ((this.state.pri3 ?  parseFloat(this.state.pri3) * parseFloat(this.state.qua3) : 0) + (this.state.otro3 ? parseFloat(this.state.otro3) : 0 ) + (this.state.abesto3 ? parseFloat(this.state.abesto3) : 0 ) + (this.state.lab3 ? parseFloat(this.state.lab3) : 0 ) + (this.state.war3 ? parseFloat(this.state.war3) : 0 )) + 
                        ((this.state.pri4 ?  parseFloat(this.state.pri4) * parseFloat(this.state.qua4) : 0) + (this.state.otro4 ? parseFloat(this.state.otro4) : 0 ) + (this.state.abesto4 ? parseFloat(this.state.abesto4) : 0 ) + (this.state.lab4 ? parseFloat(this.state.lab4) : 0 ) + (this.state.war4 ? parseFloat(this.state.war4) : 0 )) +
                        ((this.state.pri5 ?  parseFloat(this.state.pri5) * parseFloat(this.state.qua5) : 0) + (this.state.otro5 ? parseFloat(this.state.otro5) : 0 ) + (this.state.abesto5 ? parseFloat(this.state.abesto5) : 0 ) + (this.state.lab5 ? parseFloat(this.state.lab5) : 0 ) + (this.state.war5 ? parseFloat(this.state.war5) : 0 )) + 
                        ((this.state.pri6 ?  parseFloat(this.state.pri6) * parseFloat(this.state.qua6) : 0) + (this.state.otro6 ? parseFloat(this.state.otro6) : 0 ) + (this.state.abesto6 ? parseFloat(this.state.abesto6) : 0 ) + (this.state.lab6 ? parseFloat(this.state.lab6) : 0 ) + (this.state.war6 ? parseFloat(this.state.war6) : 0 ))).toFixed(2)

                    }
                    </strong>
                    </h6>
                </td>
              </tr>   
              <tr>
                <td className='text-center' style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}} ></td>
                <td style={{borderColor:'white'}} ></td>
                <td style={{borderColor:'white'}} ></td>
                <td style={{borderColor:'white'}} ></td>
                <td style={{borderColor:'white'}} ></td>
                <td style={{borderBottomColor:'white'}} ></td>
                <td style={{borderColor:'black'}} className='text-left'><h6><strong>Paid Amount $</strong></h6></td>
                <td style={{borderColor:'black'}} className='text-center' ><strong>
                  <TextField inputProps={{min: 0, style: { textAlign: 'center' }}}  
                  id="amo" type='number' label="" 
                  name="amo"
                  placeholder="  0.00"
                  variant="standard"  
                  onChange={this.myChangeHandleramo}/></strong></td>
              </tr> 
              <tr>
                <td className='text-center' style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}><Autocomplete
                      id="idpayment"
                      options={ dbdata.payment}
                      getOptionLabel={(option) => option.title}
                      style={{ width: 160 }}
                      renderInput={(params) => <TextField {...params} id="Payment" name="Payment" type='text' label="Payment methodo" variant="standard" />}
                    /> </td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderBottomColor:'white'}}></td>
                <td style={{borderColor:'black', verticalAlign:'middle'}} className='text-left'><h6><strong>Balance Due $</strong></h6></td>
                <td style={{borderColor:'black', verticalAlign:'middle'}} className='text-center' ><strong>
                <TextField inputProps={{min: 0, style: { textAlign: 'center', fontWeight: 'bold', fontSize: "large" }}} id="Balance" name="Balance"  type='text' label="" variant="standard" 
                value= 
                {
                 ((((this.state.pri1 ? parseFloat(this.state.pri1) * parseFloat(this.state.qua1) : 0)  + (this.state.otro1 ? parseFloat(this.state.otro1) : 0 ) + (this.state.abesto1 ? parseFloat(this.state.abesto1) : 0 ) + (this.state.lab1 ? parseFloat(this.state.lab1) : 0 ) + (this.state.war1 ? parseFloat(this.state.war1) : 0 )) + 
                  ((this.state.pri2 ?  parseFloat(this.state.pri2) * parseFloat(this.state.qua2) : 0)  + (this.state.otro2 ? parseFloat(this.state.otro2) : 0 ) + (this.state.abesto2 ? parseFloat(this.state.abesto2) : 0 ) + (this.state.lab2 ? parseFloat(this.state.lab2) : 0 ) + (this.state.war2 ? parseFloat(this.state.war2) : 0 )) +
                  ((this.state.pri3 ?  parseFloat(this.state.pri3) * parseFloat(this.state.qua3) : 0)  + (this.state.otro3 ? parseFloat(this.state.otro3) : 0 ) + (this.state.abesto3 ? parseFloat(this.state.abesto3) : 0 ) + (this.state.lab3 ? parseFloat(this.state.lab3) : 0 ) + (this.state.war3 ? parseFloat(this.state.war3) : 0 )) + 
                  ((this.state.pri4 ?  parseFloat(this.state.pri4) * parseFloat(this.state.qua4) : 0)  + (this.state.otro4 ? parseFloat(this.state.otro4) : 0 ) + (this.state.abesto4 ? parseFloat(this.state.abesto4) : 0 ) + (this.state.lab4 ? parseFloat(this.state.lab4) : 0 ) + (this.state.war4 ? parseFloat(this.state.war4) : 0 )) + 
                  ((this.state.pri5 ?  parseFloat(this.state.pri5) * parseFloat(this.state.qua5) : 0)  + (this.state.otro5 ? parseFloat(this.state.otro5) : 0 ) + (this.state.abesto5 ? parseFloat(this.state.abesto5) : 0 ) + (this.state.lab5 ? parseFloat(this.state.lab5) : 0 ) + (this.state.war5 ? parseFloat(this.state.war5) : 0 )) + 
                  ((this.state.pri6 ?  parseFloat(this.state.pri6) * parseFloat(this.state.qua6) : 0)  + (this.state.otro6 ? parseFloat(this.state.otro6) : 0 ) + (this.state.abesto6 ? parseFloat(this.state.abesto6) : 0 ) + (this.state.lab6 ? parseFloat(this.state.lab6) : 0 ) + (this.state.war6 ? parseFloat(this.state.war6) : 0 ))) - (this.state.amo ? parseFloat(this.state.amo) : 0)).toFixed(2)

              }
                /></strong>                  
                </td>
              </tr>  
            </tbody>
            </table>
        </Row>       
        <Row className='mb-2'>
            <Col className='text-center'>
                <h5>Customer signature</h5>
            </Col>
            <Col  className='text-center'>
                <h5>{businessname}</h5>                
            </Col>
        </Row>    
        <Row className='mb-5'>           
            <Col md={12} className='text-left'>
                <h6>NOTES / SPECIAL REQUEST(S):</h6>
                {/* <textarea  
                  style={{ fontSizeAdjust:15, width:'100%', height:'150px',  padding: "12px 20px", borderColor:'white',  fontSize: "16px", resize: "none" }}
                   id="Recommend" 
                   name="Recommend" 
                   type='text' 
                />    */}
                <textarea  
                  style={{ borderColor:'white', width:'100%',  height:'100px', resize:'vertical'}}
                   id="Recommend" 
                   name="Recommend" 
                   type='text' 
                />               
                  
            </Col>
        </Row> 
        <Row>
            <Col  className='text-center' >
                <span><strong>{bemail}</strong></span>
            </Col>
            <Col  className='text-center'>
                <h5 ><strong>Thanks for choosing us.</strong></h5>
            </Col>
            <Col >
            <Row>
                <Col>
                   <Button                                
                        type="submit"
                         variant="outlined"
                         color="primary"
                         size="small"
                         className="primary"
                         startIcon={<SaveIcon />}                  
                        >
                         Save
                    </Button>
                </Col>
                <Col>
                    <Button    
                           onClick={this.print}  
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="primary"
                            startIcon={<PrintIcon />}                  
                           >
                            Print
                       </Button>
                </Col>

              </Row>
            </Col>
        </Row>
        </form>
      </Container>
      </>
)}}
export default InvoiceLast